// @ts-nocheck

import React, {useEffect} from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Page from '../components/Page';
import {transitions, positions, Provider as AlertProvider} from 'react-alert';
import {AlertTemplate} from '../components/AlertTemplate';
import {pageView} from "../lib/ga";
import {useRouter} from "next/router";
import {NextComponentType, NextPageContext} from "next";

const alertOptions = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.SCALE
};

function MyApp(props: { Component: NextComponentType<NextPageContext, any, {}>, pageProps: any }) {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      pageView(url)
    }

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    }
  }, [router.events]);

  return <AlertProvider template={AlertTemplate} {...alertOptions}>
    <Page>
      <props.Component {...props.pageProps} />
    </Page>
  </AlertProvider>;
}

export default MyApp;
