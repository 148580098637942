// @ts-nocheck

import React from 'react';
import styled from 'styled-components';

import { StyledProps } from './Page';
import { BrandedNavBar } from './CustomNavBar';
import { Col, Row, CardImg } from 'reactstrap';

import logo from '../public/img/logo.svg';

interface IHeaderProps {
  title: string;
}

export const StyledHeader = styled.header<{}>`
  @media screen and (min-width: ${(props: StyledProps) => props.theme.mdSize}) {
    font-size: 2rem;
  }

  padding-bottom: 12px;

  .title-large,
  .title-small {
    font-family: 'Amatic SC', cursive;
  }

  .title-large {
    font-size: 69px;
  }

  .title-small {
    font-size: 40px;
  }
`;

const BrandLogo: React.FC = () => {
  return <CardImg src={logo} className={'img-responsive'} style={{ borderRadius: '100%', width: "unset", maxHeight: "100%" }} />;
};

const Header: React.FC<IHeaderProps> = ({ title }) => (
  <StyledHeader>
    <Row>
      <Col lg={11} xs={2}>
        <BrandedNavBar />
      </Col>
      <Col xs={7} className={'d-lg-none d-xl-none'}>
        <h1 className={'title-small'}>{title}</h1>
      </Col>
      <Col xs={3} className={'d-lg-none'}>
        <BrandLogo />
      </Col>
    </Row>
    <Row>
      <Col lg={9} xl={8} className={'d-none d-lg-block d-xl-block'}>
        <h1 className={'title-large'}>{title}</h1>
      </Col>
      <Col lg={3} xl={4} className={'d-none d-lg-block d-xl-block'} style={{maxHeight: "200px"}}>
        <BrandLogo />
      </Col>
    </Row>
  </StyledHeader>
);

export default Header;
