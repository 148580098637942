import React from 'react';
import Head from 'next/head';
import preview from '../../public/img/preview.jpg';

const Meta: React.FC = () => (
  <Head>
    {/* Primary Meta Tags */}
    <title>schuschu.top</title>
    <meta charSet={'UTF-8'}/>
    <meta name={"title"} content={"schuschu.top"}/>
    <meta name={'description'} content={'Selbstgestrickte Babydecken'}/>
    <meta name={'keywords'} content={'handgestrickt, selbstgestrickt, Babydecken, Geschenke, Kinder, Zuerich'}/>

    {/* Open Graph / Facebook */}
    <meta property="og:type" content="website"/>
    <meta property="og:url" content="https://schuschu.top/"/>
    <meta property="og:title" content="schuschu.top"/>
    <meta property="og:description" content="Selbstgestrickte Babydecken"/>
    <meta property="og:image" content={preview}/>

    {/* Twitter */}
    <meta property="twitter:card" content="summary_large_image"/>
    <meta property="twitter:url" content="https://schuschu.top/"/>
    <meta property="twitter:title" content="schuschu.top"/>
    <meta property="twitter:description" content="Selbstgestrickte Babydecken"/>
    <meta property="twitter:image" content={preview}/>

    {/* Others */}
    <link href={'https://fonts.googleapis.com/css2?family=Amatic+SC&display=swap'} rel={'stylesheet'}/>
    <meta content={'IE=edge'} httpEquiv={'X-UA-Compatible'}/>
    <meta name={'viewport'} content={'width=device-width, initial-scale=1.0'}/>
  </Head>
);

export default Meta;
