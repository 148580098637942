import React, { ReactNode } from 'react';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';

import Meta from '../Meta';
import { ITheme } from '../theming/ITheme';
import lightTheme from '../theming/lightTheme';
import Header from '../Header';

export interface StyledProps {
  theme: ITheme;
}

const StyledPage = styled.div`
  background: ${(props) => props.theme.backgroundColor1};
  height: 100%;
  width: 100%;
`;

const StyledMain = styled.main`
  background: ${(props) => props.theme.backgroundColor2};

  p,
  span {
    color: ${(props) => props.theme.mainTextColor1};
    font-size: 20px;
  }

  h2 {
    color: ${(props) => props.theme.mainTextColor1};
    font-size: 36px;
  }

  @media screen and (min-width: ${(props) => props.theme.mdSize}) {
    p {
      font-size: 1.25rem;
    }
  }
`;

export const Inner = styled.div`
  max-width: ${(props) => props.theme.maxWidth};
  margin: 0 auto;

  > div {
    padding: 2rem;

    @media screen and (min-width: ${(props) => props.theme.mdSize}) {
      padding: 4rem;
    }
  }
`;

const GlobalStyle = createGlobalStyle<StyledProps>`
  html, body, #__next {
    background: ${(props) => props.theme.backgroundColor1};
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
  }

  html {
    box-sizing: border-box;
    font-size: 16px;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 16px;
    line-height: 2;
  }

  a {
    color: ${(props) => props.theme.mainTextColor2};
  }
`;

export interface IPageProps {
  children: ReactNode;
}

const Page: React.FC<IPageProps> = ({ children }) => (
  <ThemeProvider theme={lightTheme}>
    <StyledPage>
      <div className={'container'}>
        <Header title={'schuschu.top'} />
        <StyledMain>
          <GlobalStyle theme={lightTheme} />
          <Meta />
          <Inner>{children}</Inner>
        </StyledMain>
      </div>
    </StyledPage>
  </ThemeProvider>
);

export default Page;
